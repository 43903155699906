@font-face {
  font-family: "Square Sans Text";
  src: url("../fonts/SquareSansText-Bold.woff2") format("woff2"),
    url("../fonts/SquareSansText-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Square Sans Text";
  src: url("../fonts/SquareSansText-SemiBold.woff2") format("woff2"),
    url("../fonts/SquareSansText-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Square Sans Text";
  src: url("../fonts/SquareSansText-Medium.woff2") format("woff2"),
    url("../fonts/SquareSansText-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Square Sans Text";
  src: url("../fonts/SquareSansText-Regular.woff2") format("woff2"),
    url("../fonts/SquareSansText-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Square Sans Text";
  src: url("../fonts/SquareSansText-Regular.woff2") format("woff2"),
    url("../fonts/SquareSansText-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --color-white: #ffffff;
  --color-black: #000000;
  --color-blue: #0060ac;
  --color-light-blue: #3380bd;
  --color-sky-blue: #e6eff7;
  --color-red: #cd1415;
  --color-pink: #fffafa;
  --color-grey: #c6c6c6;
  --color-light-grey: #d9d9d9;
  --color-porcelain: #f2f2f2;
  --color-liver: #504d4d;
  --color-tropical: #c0dff5;
  --color-border: #E1E1E1;
  --gradient-black: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );

  --font-main: "Square Sans Text", sans-serif;
}
